// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import 'slick-carousel';
import ScrollReveal from 'scrollreveal';

$(document).ready(function() {
  $('#slider').slick({
    infinite: true,
    autoplay: true
  });

  const sr = ScrollReveal();
  sr.reveal('.form-group', { duration: 2000 }, 50);
  sr.reveal('p', { duration: 2000 }, 50);

  $('a[href^="#"]:not(a[href="#"])').click(function(){
    $('html, body').animate({
      scrollTop: $('[name="' + $.attr(this, 'href').substr(1) + '"]').offset().top
    }, 1000);
    return false;
  });

  $("form").submit(function(e) {
    e.preventDefault();

    let info = {};
    $(this).serializeArray().forEach(item => info[item.name] = item.value);

    let _data = {
      "to": { "name": "Andrea Muñoz", "email": "andrea@soynutricion.mx"},
      "brand" : "Soy Nutrición",
      "enquiry" : info
    };

    $("#success").hide();
    $("#error").hide();

    $.ajax({
      contentType: "application/json; charset=utf-8",
      type: "POST",
      dataType: "json",
      data: JSON.stringify( _data ),
      url: 'https://api.coatilabs.xyz/',
      success: function(data) {
        $("#success").fadeIn('slow');
      },
      error: function(data){
        $("#error").fadeIn('slow');
      }
    });
  });
});
